<template>

  <div class="production-container">

    <div class="banner-container">
      <div class="banner-box">
        <img src="../assets/res/title4.jpg" alt="" class="banner-img">
      </div>
    </div>

    <div class="container">
      <ul class="itme-box">
        <li v-for="(item,index) in solArr" :class="{select: curIndex === index}" :key="index" v-on:click="itemClick(index)" class="con-item">
          {{ item.title }}
        </li>
      </ul>
      
      <div class="content" v-if="curIndex == 0">
        <div data-v-58bd0db6="" class="mip-sjh-text gt-jmy-pc-product-info-content">
      
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:18px;">主要功能：</span>
            </b>
          </p>
          <img src="../assets/res/mimabachang.png" class="fr-fic fr-dii" alt="">  
          <p style="align:center"></p>
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:16px;">&nbsp; &nbsp; 1，教学</span>
            </b>
          </p>
          <p>
            <span style="font-size:16px;">主要针对本科生和研究生阶段的密码教学内容,平台内容包括200多个密码教学实验，涵盖了密码原理实现、密码工程应用和密码安全分析等方面。“密码技术综合实验平台”适用于高校学生教学、个人技能培训和企业员工提升等多个场景。</span>
          </p>
          <p>
            <span style="font-size:16px;">&nbsp;
              <b>
                <span style="color:rgb(41,105,176);">&nbsp; &nbsp;2，竞赛</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">支持攻防对抗,支持全过程监控和裁判判罚，具有完备的数据分析，能力分析。</span>
          </p>
          <p>
            <span style="font-size:16px;">&nbsp;
              <b>
                <span style="color:rgb(41,105,176);">&nbsp; &nbsp;3，训练</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">提供密码学研究目标，涵盖了密码科学与技术专业课程大纲，大体分作包括数论基础算法、古典密码、杂凑算法、公钥算法、签名算法、对称算法、密码算法硬件实现、轻量级算法原理和实现、认证加密算法原理和实现、同态加密、常用密码方案、OpenSSL应用、PyCryptodome应用、PKI&数字证书、邮件加密、硬盘文件加密。</span>
          </p>
          <p>
            <span style="font-size:16px;">&nbsp;
              <b>
                <span style="color:rgb(41,105,176);">&nbsp; &nbsp;4，实验</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">提供各类典型密码应用安全实操训练环境，除视频学习外更注重实际动手能力的培养，主要解决网安团队快速培养具备实战技能人才的问题。</span>
          </p>
          <p><br></p>
          <p><br></p>
                
        </div>
      </div>


      <div class="content" v-if="curIndex == 1">
        <div data-v-58bd0db6="" class="mip-sjh-text gt-jmy-pc-product-info-content">
      
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:18px;">产品介绍：</span>
            </b>
          </p>
      
          <p>
            <span style="font-size:16px;">密码服务平台是针对客户系统中的密码应用系统与密码基础设施的一套综合管理平台，也是一种先进的密码服务综合解决方案。密码服务平台面向客户业务应用场景，提供统一的密码资源池管理、系统运维监控、密码接口规范、密码策略配置以及安全应用管理。保障密码资源集中纳管与高效运维，密码策略配置的安全性与合规性，密码功能接口调用的一致性与可扩展性等，为用户业务提供安全、高效、易用的密码应用综合管理能力。</span>
          </p>
          

          
          <p><br></p>
          <p>
            <span style="color:rgb(41,105,176);">
              <b>
                <span style="font-size:18px;">特点：</span>
              </b>
            </span>
          </p>
          <p>&nbsp; &nbsp;
            <span style="font-size:16px;">        
                <span >- 支持各厂家密码机接入，避免密码基础设施重复建设，节约密码资源建设成本，提升密码相关安全事件的响应效率。</span>           
            </span>
          </p>

          <p>&nbsp; &nbsp;
            <span style="font-size:16px;">         
                <span>- 采用密码资源池的方式对密码计算资源进行集中管理，实现密码计算资源的充分利用，和高并发快速的响应密码运算。</span>
            </span>
          </p>
          <p>&nbsp; &nbsp;
            <span style="font-size:16px;">           
                <span>- 提供密钥全生命周期管理功能，对密码用户的身份标识、策略属性、所属企业的组织架构等信息进行安全管理，并对用户的私钥进行安全分发。</span>
            </span>
          </p> 

          <p><br></p>
          <p>
            <span style="color:rgb(41,105,176);">
              <b>
                <span style="font-size:18px;">功能：</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">统一密码服务平台属于客户信息化系统中台（PaaS 层）的一部分，可独立于业务应用进行单独部署，为全体应用系统提供统一的密码服务。统一密码服务平台上接客户业务应用，下联密码基础设施，将密码基础设施统一管理并资源池化，对外提供统一服务接口和管理入口。统一密码服务平台主要构成包括：</span>
          </p>
          <p>&nbsp; &nbsp;
            <span style="font-size:16px;">        
                <span >- 应用服务层：提供用户管理、应用管理和密钥管理等功能模块，以及可选配的安全工具箱软件组件。</span>           
            </span>
          </p>

          <p>&nbsp; &nbsp;
            <span style="font-size:16px;">         
                <span>- 应用服务层：提供用户管理、应用管理和密钥管理等功能模块，以及可选配的安全工具箱软件组件。</span>
            </span>
          </p>
          <p>&nbsp; &nbsp;
            <span style="font-size:16px;">           
                <span>- 资源服务层：提供统一设备管理、运维监控和平台系统管理等功能模块，实现对平台的整体运行配置、管理、监控和审计等功能。</span>
            </span>
          </p> 
        </div>
      </div>


      <div class="content" v-if="curIndex == 9">
        <div data-v-58bd0db6="" class="mip-sjh-text gt-jmy-pc-product-info-content">
          <p>
            <span style="font-size:16px;">芯片安全测试分析平台Analyzr是一整套针对智能IC卡，芯片，终端产品，模组以及车规级产品等进行安全性分析与评估的测试平台，平台允许用户在自有系统上执行安全性分析与评估，以充分了解用户产品设计是否满足安全性以及安全标准的要求，芯片安全测试分析平台Analyzr满足多种国际测试标准，支持ISO/IEC, CC，FIPS-140等多种安全规范，完全满足安全规范的一致性测试要求。</span>
          </p>
          <p><br></p>
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:18px;">主要功能：</span>
            </b>
          </p>
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:16px;">&nbsp; &nbsp; 1，支持侧信道分析（Side Channel Analysis, SCA）</span>
            </b>
          </p>
          <p>
            <span style="font-size:16px;">支持侧信道分析与旁路攻击等业界主流的被动攻击：电磁能量攻击（Electromagnetic SCA）,电流功耗分析（Current Consumption）,电压功耗分析（Power Consumption）,支持AES-128,AES-256,DES,3-DES,RSA,ECC等标准密码算法，支持MIA,DPA,CPA,SPA,TA,MA,DEMA,LRA,NICV等多种攻击，支持国密标准算法SM2,SM4。</span>
          </p>
          <p>
            <span style="font-size:16px;">&nbsp;
              <b>
                <span style="color:rgb(41,105,176);">&nbsp; &nbsp;2，支持故障分析（Fault Injection）</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">支持故障分析等业界主流主动攻击方式：电磁故障分析（Electromagnetic Fault Injection）,激光故障分析（Laser Fault Injection）,电压毛刺（Power Glitch）以及时钟毛刺（Clock Glitch）等。提供DFA差分分析方法，配合3D自动化扫描软件，可精准定位芯片表面敏感信号点，实现精准注入。</span>
          </p>
          <p><br></p>
          <p><br></p>
          <p>
            <span style="color:rgb(41,105,176);">
              <b>
                <span style="font-size:18px;">产品优势：</span>
              </b>
            </span>
          </p>
          <p>&nbsp; &nbsp;
            <span style="font-size:16px;">
              <b>
                <span style="color:rgb(41,105,176);">- 内置Smart-Trigger 高级智能触发系统</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">Smart-Trigger高级智能触发系统支持快速数据采样率，并自带预处理功能，帮助用户更快速的找到信号触发点，实现精准触发管理，同时管理侧信道以及故障注入平台中不同设备之间的同步，协同触发以及复杂信号的快速处理，如智能卡时序分析，操作系统同步管理，高频信号噪声测量与管理等等。</span>
          </p>
          <p>
            <span style="font-size:16px;">&nbsp; &nbsp; &nbsp;
              <b>
                <span style="color:rgb(41,105,176);">- 支持Pre-Processing预处理功能</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">软件内置多个预处理模块，帮助用户预先分析及处理轨迹信号，实现轨迹信号的频率调制，高通/带通/低通滤波，以及傅里叶变换等多种处理方式，极大提高了分析软件快速定位有效信息的速度，同时基于机器学习，深度学习算法对轨迹信号进行优化及智能区分。</span>
          </p>
          <p>
            <span style="font-size:16px;">
              <b>
                <span style="color:rgb(41,105,176);">&nbsp; &nbsp; &nbsp;- 集成NICV度量技术</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">NICV泄漏度量是Secure-IC发表的泄漏分析技术，并有相关论文发表。帮助客户迅速检测泄露，快速评估攻击策略，是客户在进行泄漏模型leakages分析时的理想工具。同时提供与该算法匹配的泄漏模型，以及分析策略，通过Secure-IC的分析软件对采集的轨迹信号进行统计分析，最终以图形化方式将最终分析结果呈现给用户。</span>
          </p>
          <p>
            <span style="font-size:16px;">&nbsp; &nbsp; &nbsp;
              <span style="color:rgb(41,105,176);">
                <b>- 3D图形化及自动化分析</b>
              </span>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">以3D图形化方式显示故障分析数据，便于用户观察，同时3D制图工具也可以自动化扫描芯片表面，自动寻找芯片有效信息位置，协助用户实现故障信号自动产生和注入到芯片，对于不了解待测芯片的测试人员来说，3D图形化制图工具的自动化扫描加速了测试人员的测试速度，并提供更高的测试精度，满足黑盒测试需求。</span>
          </p>
          <p>
            <span style="font-size:16px;">&nbsp; &nbsp;
              <b>
                <span style="color:rgb(41,105,176);">&nbsp;- 精确的Fault Exploitation故障结果分析</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">故障结果分析可自动分析故障注入结果，采用DFA差分分析方法，提供best key, hypothesis以及RANK分析曲线显示功能，以及定位故障注入在算法中的哪个位置。</span>
          </p>
          <p>
            <span style="font-size:16px;">
              <b>
                <span style="color:rgb(41,105,176);">&nbsp; &nbsp; &nbsp;- 满足各类TOE测试的全功能平台</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">Analyzr平台满足目前各类消费电子产品以及车规产品的安全性测试需求，支持移动设备安全性测试，支持移动设备MCU,SIM卡等安全性测试和评估，支持车规级产品如车载电子产品以及汽车ECU等安全性测试，满足汽车行业安全测试规范SHE,EVITA,以及PRESERVE等测试要求。</span>
          </p>
          <p>
            <span style="font-size:16px;">&nbsp;
              <b>
                <span style="color:rgb(41,105,176);">&nbsp; &nbsp;- 行业内灵活的二次开发软件平台</span>
              </b>
            </span>
          </p>
          <p>
            <span style="font-size:16px;">Analyzr平台是完全开放的开发环境，完全满足客户的二次开发需求，客户可以基于平台框架开发自己的私有密码算法，以及各类分析策略,评估方法以及泄漏模型，包括但不限于私有加密算法，标准化加密算法，产品接口集成，泄漏模型，分析策略等等，支持C,C++,Java,Python等多种开发语言，同时我们也会在客户开发过程中提供技术支持，以便于客户更好的完成自有模块的开发。</span>
          </p>
          <p><br></p>
          <p><br></p>
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:18px;">设备展示：</span>
            </b>
          </p>
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:16px;">电磁故障分析平台</span>
            </b>
          </p>
          <p><img alt="图片上传" src="../assets/res/1068519638_920578867.jpg" class="fr-fic fr-dii"></p>
          <p><br></p>
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:16px;">电磁故障分析-信号产生</span>
            </b>
          </p>
          <p><img alt="图片上传" src="../assets/res/1350494367_669309705.jpg" class="fr-fic fr-dii"></p>
          <p><br></p>
          <p><br></p>
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:16px;">电磁能量攻击平台</span>
            </b>
          </p>
          <p><img alt="图片上传" src="../assets/res/441459419_1989841288.png" class="fr-fic fr-dii"></p>
          <p><br></p>
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:16px;">激光故障分析-激光工作台</span>
            </b>
          </p>
          <p><img alt="图片上传" src="../assets/res/2093464218_989242098.png" class="fr-fic fr-dii"></p>
          <p><br>
            <b>
              <span style="color:rgb(41,105,176);font-size:16px;">激光故障分析-激光生成与攻击（待测物为ARM芯片，芯片表面已经过decap开盖处理）</span>
            </b>
          </p>
          <p><img alt="图片上传" src="../assets/res/-1475679375_-472019965.png" class="fr-fic fr-dii"></p>
          <p><br></p>
          <p>
            <b>
              <span style="color:rgb(41,105,176);font-size:16px;">电压毛刺生成平台</span>
            </b>
          </p>
          <p><img alt="图片上传" src="../assets/res/-476180467_-1295062451.png" class="fr-fic fr-dii"></p>
          <p><br></p>
        </div>
      </div>

      <div class="content-cen" v-if="curIndex == 10">
        <p><br></p>
        <p>控制器爆破分析流程</p>

        <img src="../assets/res/6412.png" alt="">
        <div>
          <span style="padding:0 70px;font-weight: bold">E99 XXX MPC5777C Based</span>
          <span style="padding:0 70px;font-weight: bold">E41 XXX MPC5676R Based</span>
        </div>
        <p><br></p>
        <img src="../assets/res/6412.jpg" alt="">
        <p><br></p>
        <img src="../assets/res/pp1.jpg" alt="">
        <p><br></p>
        <p>破解AES，破解RSA，破解DES等&nbsp; &nbsp;</p>
        <p><br></p>
        <img src="../assets/res/p1.png" alt="">
        <p><br></p>
        <p><br></p>
        <p>专业的产品设备-普通版:&nbsp; &nbsp;</p>
        <p><br></p>
        <img src="../assets/res/p2.png" alt="">
        <p><br></p>
        <p><br></p>
        <img src="../assets/res/p3.png" alt="">
        <p><br></p>
        <p>专业版:&nbsp; &nbsp;</p>
        <p><br></p>
        <p><br></p>
        <img src="../assets/res/p4.png" alt="">
        <p><br></p>
        <p><br></p>
        <img src="../assets/res/p5.png" alt="">
        <p>完整工具链含硬件，上位机软件，硬件芯片固件，主要是致力于硬件安全研究</p>
        <!-- <img src="../assets/res/p6.png" alt=""> -->
      </div>

      <div class="content" style="width:910px;margin:0 auto;" v-if="curIndex == 3">
        <p><br></p>
        <p>实验室盒子工具集与设备: 长脉冲激光装置、短脉冲激光装置、EMFI设置、BBI设置、虚拟机设置、侧通道测量设置、侧频道深度学习扩展</p>
        <p><br></p>
        <p>展示：</p>
        <p><br></p>
        <img src="../assets/res/emfi.png" alt="">
        <p><br></p>
        <p style="font-weight:bold">EMFI set-up</p>
        <p>EMFI设置通过发出 电磁脉冲。为了产生这些脉冲，内部开发的线圈与EMFI结合使用 脉冲发生器。这个线圈放在离目标很近的地方 评估（TOE）。强电的短暂存在 磁场会产生一种电流，这种电流可能会影响 TOE</p>

        <p><br></p>
        <img src="../assets/res/bbi.png" alt="">
        <p><br></p>
        <p style="font-weight:bold">BBI set-up</p>
        <p>BBI设置类似于EMFI设置。主要 不同之处在于能量注入芯片的方式。哪里 EMFI使用靠近芯片表面（金属侧）的线圈， BBI使用一根针放在基板上（硅侧） 将能量局部注入产品。与EMFI一样，这可能会影响TOE的行为。</p>

        <p><br></p>
        <img src="../assets/res/laster.png" alt="">
        <p><br></p>
        <p style="font-weight:bold"> Laser set-ups: LPL and SPL</p>
        <p>BSLB配有两个激光装置。为什么是两个？因为 激光源之间有很大的区别 在这些设置中，这意味着它们会影响不同的方式。SPL是一种强大的激光器，能发出非常微弱的光 短脉冲。使用非常低的设置，可以影响 脚趾的行为。LPL的功能不如 SPL，但它会产生更长的脉冲，这会影响一个系统中的硅 不同的方式。当用LPL或SPL攻击芯片时 可以瞄准芯片的金属侧或硅侧</p>

        <p><br></p>
        <img src="../assets/res/vm.png" alt="">
        <p><br></p>
        <p style="font-weight:bold">VM set-up</p>
        <p>VM设置通过电源线注入故障产品名称。要注入这些故障，需要向 产生一个能量峰值。这些尖峰会导致处理器略过或曲解指令，但也会引起记忆 断层。</p>

        <p><br></p>
        <img src="../assets/res/side.png" alt="">
        <p><br></p>
        <p style="font-weight:bold">Side-Channel Measurement set-up</p>
        <p>通过SCM设置，您可以进行测量，以便 对IC、智能卡和终端执行攻击。你可以 测量功耗和/或电磁辐射 你的样品。这些侧边通道显示信息 关于这些设备上运行的进程。统计的 对测量结果的分析可能会揭示秘密 来自这些侧通道的信息，如引脚值或 加密密钥。
        </p>

        <p><br></p>
        <img src="../assets/res/deep.png" alt="">
        <p><br></p>
        <p style="font-weight:bold">Side-Channel Deep Learning Extension</p>
        <p>深度学习扩展配置为执行 对数据进行计算密集型分析 侧通道分析测量装置。这样的分析 包括深度学习、模板攻击等 统计分析，以及从中受益的任何其他任务 在高性能PC上运行。
        </p>
        <p><br></p>
        <p><br></p>
        <p><br></p>
      </div>

      <div class="content" style="width:1170px;margin:0 auto;" v-if="curIndex == 2">
        <div class="pro-list">
          <div class="pro-item">
            <div class="left-box">
              <img  src="../assets/res/cp-ipsecVpn.jpg" alt="">
            </div>
            <div class="right-box">
              <div class="title">IPSec VPN安全网关</div>
              <div class="text">IPSec VPN安全网关是专门为用户内部网络数据的传输、加密、完整性保护、鉴别等需求而设计，为用户提供数据安全加密、IPSec VPN功能。 可以实现政府、集团公司、连锁经营大中型企业的总部与各个级别分支机构、移动办公人员的远程接入联网需求，可以为远程接入提供透明的数据的传输加密，完整性校验等安全防护。</div>
              <div class="subtitle">产品优势</div>
              <div class="text">网络兼容性:网络兼容性</div>
              <div class="text">证书兼容性:同时可以支持国际与国密算法的证书；</div>
              <div class="text">双机热备:支持多组虚拟热备，支持热备监控及告警,支持设备间数据同步，支持数据备份与恢复。</div>
              <div class="text">服务器负载均衡:专业级负载均衡，支持四层和七层负载均衡。支持多种极重调度算法,支持会话保持、连接复用、高速缓存、HTTP压缩，支持URL映射管理，支持线性扩容,支持服务器健康检査，支持故障和高负荷告警。</div>
              <div class="text">数字证书认证:支持单向和双向SSL认证，支持OCSP协议和CRL列表，确保通信方身份合法性。同时支持多个业务系统，可设置多个不同信任证书链,支持用户信息透传,支持错误页面定制,适合大多数业务场景。</div>
              <div class="text">审计与报表:实时图表展示系统资源负荷情况、每秒新建用户连接数、并发用户量、系统吞吐塁，实时査看各业务组服务器上的用户负载情况，方便运维管理员监控系统资源及业务状态。</div>
            </div>
          </div>
          <div class="pro-item">
            <div class="left-box">
              <img  src="../assets/res/cp-ipsecVpn.jpg" alt="">
            </div>
            <div class="right-box">
              <div class="title">C100 密码卡(PCI-E)</div>
              <div class="text">C100 密码卡产品，是以PCI Express为总线接口的高速密码设备，支持对称运算、杂凑运算、非对称运算、产生真随机数等密码运算功能，同时，提供完备的密钥管理及存储功能。 C100 (MINI) 版是一款针对MINI PCI-E接口设计的加密卡。</div>
              <div class="subtitle">产品优势</div>
              <div class="text">对称运算：支持SM1和SM4分组密码算法，实现ECB/CBC工作模式。</div>
              <div class="text">哈希运算：可对数据实现基于SM3密码杂凑算法的HASH运算。</div>
              <div class="text">MAC运算：可对数据实现基于SM1和SM4分组密码算法的MAC运算。</div>
              <div class="text">HMAC运算：可对数据实现基于SM3密码杂凑算法的HMAC运算。</div>
              <div class="text">非对称运算：支持SM2椭圆曲线公钥密码算法，可实现对数据的加密/解密以及签名/验签。</div>
              <div class="text">物理随机数：内置由国家密码管理局批准使用的双路物理噪声源芯片，提供真随机数序列的生成。</div>
              <div class="text">密钥管理：支持符合国家密码管理局安全性要求的密钥结构体制，密钥的产生、存储、使用、更换、销毁、备份及恢复均符合国家密码管理局的安全性设计要求。</div>
              <div class="text">用户鉴别：提供基于角色的用户鉴别，可通过 口令／USBKey／口令+USBKey 实现用户权限控制。</div>
              <div class="text">其它：支持用户对卡内专属非易失性存储空间进行读写操作。</div>
            </div>
          </div>
        </div>
      </div>

      <div class="content" style="width:1210px;margin:0 auto;" v-if="curIndex == 5">
        <div class="idea">
          <div class="idea-title">端到端一站式安全检测，提供专业检测报告</div>
          <div class="idea-content">
            <div class="idea-img"></div>
          </div>
        </div>

        <!-- 服务内容 -->
        <div class="price">
          <div class="idea-title">服务内容</div>
          <div class="price-outer">
            <div class="first-line">
              <div class="first-title service-type first-width">
                <span class="first-title-type">服务类别</span>
              </div>
              <div class="first-title fix-width">
                <span class="first-title-type">服务项目</span>
              </div>
              <div class="first-title free-type fix-width">
                <span class="first-title-free">免费版</span>
              </div>
              <div class="first-title-last pay-type fix-width">
                <span class="first-title-pay">商业版</span>
              </div>
            </div>
            <div class="second-line">
              <div class="second-left first-width">
                <span class="second-title-type">基础服务</span>
              </div>
              <div class="second-left">
                <div class="second-title fix-width">动/静态双检测引擎</div>
                <div class="second-title fix-width">设备固件检测</div>
                <div class="second-title fix-width">APP安全检测</div>
                <div class="second-title fix-width">云平台/API检测</div>
                <div class="second-title-last fix-width">数百种检测策略</div>
              </div>
              <div class="second-left">
                <div class="second-title fix-width">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title fix-width">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title fix-width">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title fix-width">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title-last fix-width">
                  <div class="ok-icon"></div>
                </div>
              </div>
              <div class="second-left-last">
                <div class="second-title fix-width">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title fix-width">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title fix-width">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title fix-width">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title-last fix-width">
                  <div class="ok-icon"></div>
                </div>
              </div>
            </div>
            <div class="third-line">
              <div class="second-left first-width">
                <span class="second-title-type">高级服务</span>
              </div>
              <div class="second-left">
                <div class="second-title">漏洞库更新</div>
                <div class="second-title">检测报告类型</div>
                <div class="second-title">安全检测次数</div>
                <div class="second-title">漏洞修复建议</div>
                <div class="second-title-last">企业API支持</div>
              </div>
              <div class="second-left">
                <div class="second-title">每季度</div>
                <div class="second-title">简版</div>
                <div class="second-title">6 次/年</div>
                <div class="second-title">简版</div>
                <div class="second-title-last">—</div>
              </div>
              <div class="second-left-last">
                <div class="second-title">每周 * 支持高危漏洞紧急更新</div>
                <div class="second-title">完整版</div>
                <div class="second-title">无限次</div>
                <div class="second-title">完整版</div>
                <div class="second-title-last">
                  <div class="ok-icon"></div>
                </div>
              </div>
            </div>
            <div class="four-line">
              <div class="four-left">
                <span class="four-title-type first-width">专家服务</span>
              </div>
              <div class="four-left-middle">
                <div class="second-title">专属物联网安全方案设计服务</div>
                <div class="second-title">物联网安全专家咨询服务</div>
                <div class="second-title">IOTVD物联网威胁情报订阅服务</div>
                <div class="second-title">物联网安全技术课程培训服务</div>
                <div class="second-title">青莲云物联网安全沙龙优先参与权</div>
                <div class="second-title-last">专属物联网安全顾问（1对1服务）</div>
              </div>
              <div class="four-left-middle">
                <div class="second-title">—</div>
                <div class="second-title">—</div>
                <div class="second-title">—</div>
                <div class="second-title">—</div>
                <div class="second-title">—</div>
                <div class="second-title-last">—</div>
              </div>
              <div class="four-left-last">
                <div class="second-title">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title">
                  <div class="ok-icon-two"></div>
                  <div class="icon-text">1次/季度(含1次现场服务)</div>
                </div>
                <div class="second-title">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title">1次(支持现场培训)</div>
                <div class="second-title">
                  <div class="ok-icon"></div>
                </div>
                <div class="second-title-last">5 * 8</div>
              </div>
            </div>

          </div>
        </div>
        <!-- 技术优势 -->
        <div class="core">
          <div class="core-content">
            <div class="box-top">技术优势</div>
            <ul class="core-main">
              <li class="core-list">
                <div class="core-img-one"></div>
                <div class="core-item-title">风险评估</div>
                <div class="core-item">基本信息分析</div>
                <div class="core-item">文件系统分析</div>
                <div class="core-item">安全等级评估</div>
                <div class="core-item"></div>
                <div class="core-item"></div>
              </li>
              <li class="core-list">
                <div class="core-img-two"></div>
                <div class="core-item-title">漏洞检索</div>
                <div class="core-item">固件漏洞检索</div>
                <div class="core-item">web漏洞检索</div>
                <div class="core-item">应用漏洞检索</div>
                <div class="core-item"></div>
                <div class="core-item"></div>
              </li>
              <li class="core-list">
                <div class="core-img-three"></div>
                <div class="core-item-title">安全隐患扫描</div>
                <div class="core-item">密码信息扫描</div>
                <div class="core-item">本地弱密码校验</div>
                <div class="core-item">敏感信息扫描</div>
                <div class="core-item">加密认证文件检索</div>
                <div class="core-item"></div>
              </li>
            </ul>
            <ul class="core-main">
              <li class="core-list">
                <div class="core-img-four"></div>
                <div class="core-item-title">自研学习知识库</div>
                <div class="core-item">CVE漏洞库</div>
                <div class="core-item">物联网安全漏洞库</div>
                <div class="core-item">敏感信息规则库</div>
                <div class="core-item">物联网应用组件数据库</div>
                <div class="core-item">安全特征库</div>
              </li>
              <li class="core-list">
                <div class="core-img-five"></div>
                <div class="core-item-title">深度剖析与深度测试</div>
                <div class="core-item">防火墙绕过检测</div>
                <div class="core-item">漏洞利用测试</div>
                <div class="core-item">深度剖析漏洞原理</div>
                <div class="core-item">Url日志列表导入扫描</div>
                <div class="core-item"></div>
              </li>
              <li class="core-list">
                <div class="core-img-six"></div>
                <div class="core-item-title">数据可视化</div>
                <div class="core-item">云端数据可视化</div>
                <div class="core-item">本地报告永久保存</div>
                <div class="core-item"></div>
                <div class="core-item"></div>
                <div class="core-item"></div>
              </li>
            </ul>
          </div>
          <div class="core-img"></div>
        </div>

      </div>
      
      <div class="content" v-if="curIndex == 6">
        <img src="../assets/res/product1.jpg" alt="">
        <p>EM Probe Station：</p>
        <p>
          1）控制台xyz方向可调；2）步进小于等于2.5μm；3）可重复误差低于50μm；4）扫描区域大于等于50X50mm；5）有高灵敏度及低灵敏度2个电磁感应探头，以用于不同类型芯片测试。内带低噪声差分放大器： 15pT/√Hz@1MHz（高灵敏度探头）；3pT/√Hz@1MHz（低灵敏度探头）。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      curIndex: 0,
      solArr: [
        {
          title: "密码靶场"
        },
        {
          title: "密码服务平台"
        },
        {
          title: "信息安全"
        },
        // {
        //   title: "Brightsight Security Lab"
        // },
        // {
        //   title: "密评工具"
        // },
        // {
        //   title: "IoT安全检测平台"
        // }
      ],
      mpArr: [
        {
          title: "IPSec VPN安全网关",
          url: require("../assets/res/cp-ipsecVpn.jpg"),
          content: "IPSec VPN安全网关是专门为用户内部网络数据的传输、加密、完整性保护、鉴别等需求而设计，为用户提供数据安全加密、IPSec VPN功能。 可以实现政府、集团公司、连锁经营大中型企业的总部与各个级别分支机构、移动办公人员的远程接入联网需求，可以为远程接入提供透明的数据的传输加密，完整性校验等安全防护。"
        },
        {
          title: "商用密码安全协议分析工具",
          url: require("../assets/res/mp2.png"),
          content: "该工具主要用作对信息系统中使用的通讯协议进行评估。该工具可对密码系统中数据网络传输进行监控，通过该工具可抓取网络传输数据包，根据工具配置中提供的通讯协议种类和密码算法种类等信息，工具会自动对抓取数据包进行解析，验证该数据包中应用的协议种类和算法是否与预期匹配，如匹配则将同步验证密码算法实现是否正确。工具支持IPSEC、SSL等常见的通讯协议。通过该工具，测试人员将具备对密码系统中通讯协议进行密码算法测试的能力。"
        },
        {
          title: "商用密码算法验证工具",
          url: require("../assets/res/mp2.png"),
          content: "该工具主要用于验证信息系统中使用到的密码算法相关的模块是否正确实现了密码算法。通过该工具可对密码系统中实现密码算法功能的模块进行评估，工验证该模块的密码算法实现是否正确。设置好密码算法种类，数据格式，数据端口等配置后，工具将自动抓取相关密码模块的数据输入与输出，并对获取到的数据进行智能分析。可对算法的正确性和合规性进行测试。支持验证的算法包括：国密SM2、SM3、SM4、SM9、ZUC算法。国际RSA、DES、SHA1-3、MD1-5、RC4、ECC算法，通过该工具的建设，测试人员将具备对密码系统中密码算法实现正确性的测试能力。"
        },
        {
          title: "密码基线测试工具",
          url: require("../assets/res/mp4.png"),
          content: "该工具可对密码系统所使用的密码策略进行自动化测试，可验证策略设置是否合理，满足安全要求。通过该工具可对密码系统中所使用的密钥及相关策略配置文件进行扫描分析。根据设置的预期密钥应用条件和策略配置，工具通过系统接口，自动进行密码算法调用测试，从密钥长度，强弱密钥，抗暴力攻击等方面对系统进行测试。通过该工具的建设，测试人员将具备对密码系统中密钥策略合规性的测试能力。"
        },
        {
          title: "数据存储安全工具",
          url: require("../assets/res/mp5.png"),
          content: "该工具主要对信息系统的数据存储功能进行安全测试。工具中包含有常见敏感信息限制库，同时支持敏感信息自定义功能，测试员可通过该功能，自定义添加敏感信息数据特征。根据设置好的敏感信息条件，工具可对密码系统所使用的数据库、系统文件、日志等进行自动化扫描和验证，测试是否含有敏感数据。通过该工具的建设，测试人员将具备对密码系统中敏感信息存储的扫描测试能力。"
        },
        {
          title: "流程不可抵赖测试工具",
          url: require("../assets/res/mp6.png"),
          content: "该工具主要用于验证信息系统中通讯双方的消息流是否应用了抗抵赖算法，且算法应用是否有效。该工具可对密码系统的应用流程进行监控，并抓取网络传输数据包，并通过伪包、篡改等方式验证不可抵赖算法或措施的有效性。通过该工具的建设，测试人员将具备对密码系统双向通信抗抵赖机制的验证能力。"
        },
        {
          title: "密码实现漏洞扫描工具",
          url: require("../assets/res/mp7.png"),
          content: "该工具主要对信息系统中设计密码算法实现的相关模块进行测试，确保系统中实现密码算法的相关代码没有安全漏洞。工具中已包含公开的常见漏洞库，可对密码算法实现的源代码进行扫描，识别是否存在的缓冲溢出等安全漏洞。通过该工具的建设，测试人员将具备对密码系统的源码安全扫描能力。"
        },
        {
          title: "商用密码安全协议分析工具",
          url: require("../assets/res/mp8.png"),
          content: "该工具主要对信息系统运行的载体环境进行扫描测试。支持对应用商用密码的主机配置自动化核查，并能够对主机配置进行脆弱性检测分析。通过该工具，可对密码系统的运行环境条件进行核查，分析相关安全配置是否合理有效，是否存在配置弱点。通过该工具的建设，测试人员将具备对密码系统主机运行环境的安全扫描能力。"
        },
        {
          title: "数据随机性检测工具",
          url: require("../assets/res/mp9.png"),
          content: "该该工具主要用作对商用密码算法应用的随机数的随机性和加密后的数据熵值进行检测分析。能够对商用密码算法应用的随机数的随机性进行检测分析。通过该工具的建设，测试人员将具备对密码系统中使用的随机数质量的验证能力。"
        }
      ]
    }
  },
  components: {

  },
  methods: {
    itemClick: function (index) {
      this.curIndex = index;
    }
  }
}
</script>

<style scoped lang="scss">
.production-container {
  .banner-container {
    width: 1200px;
    margin: 0 auto;
    .banner-box {
      margin-top: 5px;
      .banner-img {
        width: 1200px;
        height: 256px;
      }
    }
  }
  .container {
    width: 1170px;
    margin: 0 auto;
    min-height: 600px;
    .itme-box {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
      border-bottom: 2px #ac0000 solid;
      .con-item {
        width: 240px;
        height: 36px;
        line-height: 36px;
      }
      .con-item:hover {
        margin-bottom: -2px;
        color: #ac0000;
        border-top: 2px #ac0000 solid;
        border-bottom: 2px #fff solid;
        height: 34px;
        line-height: 34px;
      }
      .select {
        margin-bottom: -2px;
        color: #ac0000;
        border-top: 2px #ac0000 solid;
        border-bottom: 2px #fff solid;
        height: 34px;
        line-height: 34px;
      }
    }
    .content {
      margin: 20px 5px 0 0;
      p {
        font-size: 16px;
        line-height: 1.8;
        padding: 0;
        margin: 0;
        white-space: normal;
        word-wrap: break-word;
        word-break: normal;
        overflow: hidden;
        width: 100%;
        text-align: left;
      }
      .fr-fic{
        width: 40%;
        height: 40%;
      }
      .pro-list {
        padding: 40px 0;
        .pro-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          //align-items: center;
          padding: 30px 0;
          border-bottom: 1px solid #dbdbdb;
          .left-box {
            width: 500px;
            // height: 280px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
            }
          }
          .right-box {
            width: 100%;
            text-align: left;
            .title {
              font-size: 30px;
              font-weight: 500;
            }
            .text {
              font-size: 16px;
              font-weight: 500;
              padding-top: 10px;
              line-height: 16px;
            }
            .subtitle {
              padding-top: 20px;
              font-size: 20px;
              font-weight: 500;
              line-height: 40px;
            }
          }
        }

        .pro-item:last-child {
          border-bottom: none;
        }
      }

      .idea-title {
        text-align: center;
        height: 36px;
        font-size: 24px;
        line-height: 36px;
      }

      .idea-content {
        width: 1210px;
        /* height: 902px; */
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        margin: 64px auto 90px;
        background-color: #fff;
        position: relative;

        .idea-img {
          background: url(../assets/res/iot1.png) no-repeat;
          background-size: cover;
          height: 480px;
          margin: 60px 92px;
        }
      }
    }

    .content-cen {
      margin: 20px 5px 0 0;
      p {
        font-size: 16px;
        line-height: 1.8;
        padding: 0;
        margin: 0;
        white-space: normal;
        word-wrap: break-word;
        word-break: normal;
        overflow: hidden;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
<style scoped>
.head-back {
  background-color: #373841;
  /*padding-top: 8px;*/
  padding-bottom: 1px;
}
.head {
  height: 488px;
  width: 1210px;
  margin: 0 auto;
  /* background: url('../assets/res/firmware_check.png') no-repeat; */
  background-size: cover;
  padding-top: 136px;
}

.head-left {
  width: 100%;
  float: left;
  height: 100%;
}

.head-title {
  font-size: 44px;
  color: #fff;
  text-align: left;
  height: 72px;
  line-height: 72px;
}

.title-content {
  width: 70%;
  text-align: left;
}

.head-title-two {
  height: 32px;
  font-size: 20px;
  color: #fff;
  margin-top: 16px;
  margin-bottom: 48px;
}
.head-button {
  width: 148px;
  float: left;
  margin-right: 24px;
}
.button-content {
  width: 148px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 16px;
  color: #38b487;
  border: 1px solid #38b487;
  border-radius: 2px;
  font-weight: 600;
}
.button-content:hover {
  background-color: #38b487;
  color: #ffffff;
}
.check-img {
  width: 271px;
  height: 251px;
  margin: auto;
}

.idea {
  position: relative;
  width: 100%;
  height: 870px;
  padding-top: 80px;
  background-color: #ffffff;
}
.idea-title {
  text-align: center;
  height: 36px;
  font-size: 24px;
  line-height: 36px;
}

.idea-content {
  width: 1210px;
  /*height: 902px;*/
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 64px auto 90px;
  background-color: #fff;
  position: relative;
}
.first-back {
  height: 462px;
  padding-left: 4px;
  padding-right: 4px;
  /* background: url('../assets/res/about_us_back.png') no-repeat; */
  background-size: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.bottom-back {
  height: 462px;
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  /* background: url('../assets/res/about_us_back.png') no-repeat; */
  background-size: cover;
  transform: rotate(180deg);
  position: absolute;
  bottom: 0;
}

.idea-img {
  /* background: url('../assets/res/idea_content_words.png') no-repeat; */
  background-size: cover;
  height: 480px;
  margin: 60px 92px;
}
.idea-table {
  width: 100%;
  height: 310px;
  background-color: #f7fafa;
  border-radius: 5px;
  padding-top: 40px;
  padding-left: 40px;
}
.idea-table-content {
  font-size: 16px;
  color: #333333;
  font-family: PingFangSC, Helvetica Neue, Helvetica, Roboto, Arial,
    Hiragino Sans GB, Microsoft Yahei, WenQuanYi Micro Hei, sans-serif !important;
}
.idea-table-list {
  margin-top: 20px;
}
.idea-table-img {
  /* background: url('../assets/res/idea_table_list.png') no-repeat; */
  background-size: cover;
  width: 12px;
  height: 12px;
  float: left;
  margin-top: 7px;
  margin-bottom: 7px;
}
.idea-table-list-title {
  margin-left: 20px;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  color: #333333;
}
.idea-table-list-title-content {
  margin-left: 20px;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  color: #666666;
}

.core {
  background-color: #fff;
  height: 920px;
  position: relative;
}
.core-content {
  width: 1210px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 90px;
  text-align: center;
  position: relative;
  z-index: 1;
}
.box-top {
  font-size: 24px;
  color: #212121;
  height: 36px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 100px;
}
.core-main {
  width: 1210px;
  margin: 0 auto 100px;
  text-align: center;
  height: 245px;
}
.core-main-two {
  width: 1210px;
  margin: 0 auto;
  text-align: center;
}

.core-list {
  float: left;
  width: 368px;
  background-color: #f7fafa;
  margin: auto 16px;
  position: relative;
  padding-top: 65px;
  padding-bottom: 30px;
}
.core-img-one {
  background: url('../assets/res/tech_one.png') no-repeat;
  background-size: cover;
  height: 72px;
  width: 62px;
  position: absolute;
  top: -36px;
  left: 153px;
}
.core-img-two {
  background: url('../assets/res/tech_two.png') no-repeat;
  background-size: cover;
  height: 72px;
  width: 62px;
  position: absolute;
  top: -36px;
  left: 153px;
}
.core-img-three {
  background: url('../assets/res/tech_three.png') no-repeat;
  background-size: cover;
  height: 72px;
  width: 62px;
  position: absolute;
  top: -36px;
  left: 153px;
}
.core-img-four {
  background: url('../assets/res/tech_four.png') no-repeat;
  background-size: cover;
  height: 72px;
  width: 62px;
  position: absolute;
  top: -36px;
  left: 153px;
}
.core-img-five {
  background: url('../assets/res/tech_five.png') no-repeat;
  background-size: cover;
  height: 72px;
  width: 62px;
  position: absolute;
  top: -36px;
  left: 153px;
}
.core-img-six {
  background: url('../assets/res/tech_six.png') no-repeat;
  background-size: cover;
  height: 72px;
  width: 62px;
  position: absolute;
  top: -36px;
  left: 153px;
}

.core-item-title {
  font-size: 20px;
  color: #38b487;
  height: 30px;
  line-height: 30px;
  margin-bottom: 24px;
}

.core-item {
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  color: #535353;
}

.core-img-parent {
  width: 100%;
  text-align: center;
}
.price {
  background-color: #f7f7f7;
  padding-top: 64px;
  padding-bottom: 26px;
}
.price-outer {
  width: 1174px;
  background-color: #fff;
  margin: 64px auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.first-line {
  height: 80px;
  border-bottom: 1px solid #e9e9e9;
}
.first-title {
  width: 232px;
  float: left;
  height: 80px;
  line-height: 80px;
  color: #333333;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-right: 1px solid #e9e9e9;
}
.first-title-last {
  width: 310px;
  float: left;
  height: 80px;
  line-height: 80px;
  color: #333333;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.first-title-type {
  width: 100%;
  float: left;
  height: 60px;
  line-height: 60px;
  color: #333333;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
  margin: 10px auto;
}
.first-title-free {
  width: 100%;
  float: left;
  height: 60px;
  line-height: 60px;
  color: #38b487;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
  margin: 10px auto;
}
.first-title-pay {
  width: 100%;
  float: left;
  height: 60px;
  line-height: 60px;
  color: #4f8dff;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
  margin: 10px auto;
}
.second-line {
  height: 280px;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
}
.second-title-type {
  width: 100%;
  float: left;
  height: 260px;
  line-height: 260px;
  color: #333333;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
  margin: 10px auto;
}
.second-left {
  float: left;
  width: 310px;
  height: 280px;
  border-right: 1px solid #e9e9e9;
}
.second-left-last {
  float: left;
  width: 310px;
  height: 277px;
}
.second-title {
  width: 100%;
  height: 56px;
  line-height: 55px;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
  color: #666666;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.second-title-last {
  width: 100%;
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: #666666;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.third-line {
  height: 280px;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
}
.four-line {
  height: 335px;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
}
.four-left {
  float: left;
  width: 240px;
  border-right: 1px solid #e9e9e9;
}
.four-left-middle {
  float: left;
  width: 310px;
  border-right: 1px solid #e9e9e9;
}
.four-title-type {
  width: 100%;
  float: left;
  line-height: 335px;
  color: #333333;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
}
.five-line {
  height: 100px;
  border-top: 1px solid #e9e9e9;
}
.five-title-price {
  float: left;
  width: 240px;
  height: 99px;
  border-right: 1px solid #e9e9e9;
  line-height: 99px;
  text-align: center;
  color: #333333;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.five-title {
  width: 310px;
  float: left;
  height: 99px;
  border-right: 1px solid #e9e9e9;
  text-align: center;
  padding-top: 29px;
}
.five-title-last {
  width: 310px;
  float: left;
  height: 99px;
  text-align: center;
  padding-top: 29px;
}
.four-left-last {
  width: 310px;
  float: left;
}
.free-type {
  border-top: 5px solid #38b487;
  border-radius: 6px 6px 0 0;
}
.pay-type {
  border-top: 5px solid #4f8dff;
  border-radius: 6px 6px 0 0;
}
.service-type {
  border-radius: 10px 0 0 0;
}
.ok-icon {
  width: 16px;
  height: 16px;
  background: url('../assets/res/right.png') no-repeat;
  background-size: cover;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 152px;
}
.ok-icon-two {
  width: 16px;
  height: 16px;
  background: url('../assets/res/right.png') no-repeat;
  background-size: cover;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 80px;
}
.icon-text {
  width: 280px;
}
.first-width {
  width: 240px;
}
.fix-width {
  width: 310px !important;
}
.free-use {
  width: 148px;
  height: 40px;
  background-color: #38b487;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  line-height: 40px;
  margin: 0 auto;
}
.free-ask {
  width: 148px;
  height: 40px;
  background-color: #4f8dff;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  line-height: 40px;
  margin: 0 auto;
}
.ask-me:hover {
  cursor: pointer;
}
.ask-me-first {
  color: #38b487;
}
.ask-me-first:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}
.free-use:hover {
  background-color: #32a179;
}
.free-ask:hover {
  cursor: pointer;
  background-color: #3f70cc;
}
.model {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-out;
  overflow-y: auto;
}
.cancel img {
  display: block;
  border: 0;
  width: 100%;
  height: 100%;
}
.cancel {
  position: absolute;
  top: -40px;
  right: 0;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.yang-outer {
  width: 160px;
  height: 160px;
  border: 1px solid #999;
}
.wechat {
  width: 160px;
  height: 160px;
}
.wechat-text {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin-top: 12px;
  width: 210px;
  position: relative;
  left: -15px;
}
.core-img {
  height: 462px;
  width: 100%;
  position: absolute;
  bottom: 0;
  /* background: url(../assets/res/about_us_back.png) no-repeat; */
  background-size: cover;
  transform: rotate(180deg);
}
.customer {
  width: 100%;
  background-color: #f7f7f7;
  padding-top: 80px;
  padding-bottom: 26px;
}
.customer-title {
  font-size: 24px;
  font-weight: 400;
  color: rgba(33, 33, 33, 1);
  text-align: center;
}
.companies {
  height: 296px;
  width: 1086px;
  margin: 64px auto;
}
.company-logo {
  margin-bottom: 16px;
  overflow: hidden;
}
</style>

